import React, { useEffect, useState } from "react";
import { pages } from "./constants";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MultiInput from "../../shared/SelectMultiOptions";
import TourService from "../package-form/service";
import { Controller } from "react-hook-form";
import SeoFormService from "./service";
import CallEnquiryFormService from "../call-enquiry-form/service";

const SeoDetails = ({
  SeoDetails,
  ogDetails,
  twitterDetails,
  handleSeoDetailsChange,
  handleTwitterDetailsChange,
  handleOgDetailsChange,
  handleUploadFile,
  handleUploadOgImage,
  handleUploadTwitterImage,
  destinationName,
  packageName,
  themeName,
  thingsToDoName,
  placesToVisitName,
  packageId,
  setPackageId,
  groupId,
  setGroupId,
  groupName,
  destinationId,
  setDestinationId,
  themeId,
  setThemeId,
  thingsToDo,
  setThingsToDo,
  placesToVisit,
  setPlacesToVisit,
  setStoryId,
  storyName,
  formType,
  control,
}) => {
  const removeFile = () => {
    handleSeoDetailsChange("favicon", "");
  };

  const [keywords, setKeywords] = useState(
    formType === "edit" ? SeoDetails.keywords : []
  );
  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [themeSuggestion, setThemeSuggestion] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packageSuggestion, setPackageSuggestion] = useState([]);
  const [groupPackageSuggestion, setGroupPackageSuggestion] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [thingsToDoData, setThingsToDoData] = useState([]);
  const [placesToVisitData, setPlacesToVisitData] = useState([]);

  useEffect(() => {
    handleSeoDetailsChange("metaKeywords", keywords);
  }, [keywords]);

  useEffect(() => {
    handleDestinationSuggestion("");
    handleThemeSuggestion();
    handlePackageSuggestion("");
    handleThingsToDo();
    handlePlacesToVisit();
    handleStory();
    handleGroupPackageSuggestion();
  }, []);

  useEffect(() => {
    setPlacesToVisit("");
    setThingsToDo("");
    setThemeId("");
    setDestinationId("");
    setPackageId("");
    setStoryId("");
  }, [SeoDetails.page]);

  const handleStory = async () => {
    const { data } = await SeoFormService.fetchStory();
    data && setStoryData(data);
  };
  const handlePlacesToVisit = async () => {
    const { data } = await SeoFormService.fetchPlacesToVisit();
    data && setPlacesToVisitData(data);
  };
  const handleThingsToDo = async () => {
    const { data } = await SeoFormService.fetchThingsToDo();
    data && setThingsToDoData(data);
  };

  const handlePackageSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await CallEnquiryFormService.packageSuggestion(
      value
    );
    if (isSuccess) {
      setPackageSuggestion(data?.data);
      if (data?.data?.length < 2) setPackageId(data?.data[0]?._id);
    }
  };
  const handleGroupPackageSuggestion = async () => {
    const { data, isSuccess } =
      await CallEnquiryFormService.groupPackageSuggestion();

    if (isSuccess) {
      setGroupPackageSuggestion(data?.data);
    }
  };

  const handleThemeSuggestion = async (value) => {
    const { data, isSuccess } = await TourService.themeSuggestion(value);
    isSuccess && setThemeSuggestion(data?.data);
  };

  const handleDestinationSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await TourService.destinationSuggestion(value);
    if (isSuccess) {
      setDestinationSuggestion(data?.data);
      if (data?.data?.length < 2) setDestinationId(data?.data[0]?._id);
    }
  };

  const getDestinationField = (page) => {
    if (
      page === "destination" ||
      page === "destination-cities" ||
      page === "destination-stories" ||
      page === "destination-things-todo"
    )
      return (
        <Grid item md={12} sm={12} xs={12}>
          <Autocomplete
            id="destination"
            options={destinationSuggestion?.map((option) => option.name)}
            defaultValue={destinationName}
            onChange={(event, newValue) => {
              handleDestinationSuggestion(newValue);
            }}
            getOptionLabel={(option) => (option ? option : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      );
  };

  return (
    <>
      <Typography className="heading">SEO Details</Typography>
      <Grid container spacing={1}>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Page"
            select
            variant="outlined"
            value={SeoDetails.page}
            onChange={(e) => handleSeoDetailsChange("page", e.target.value)}
            fullWidth
          >
            {pages.map((option, ind) => (
              <MenuItem key={ind} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Title"
            variant="outlined"
            value={SeoDetails.title}
            onChange={(e) => handleSeoDetailsChange("title", e.target.value)}
            fullWidth
          />
        </Grid>

        {getDestinationField(SeoDetails.page)}
        {SeoDetails.page === "theme" && (
          <Grid item md={12} xs={12} sm={12}>
            <Grid container spacing={1}>
              {formType === "edit" && (
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    label="Previously Selected Theme"
                    variant="outlined"
                    value={themeName}
                    disabled
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  fullWidth
                  select
                  defaultValue={themeName ? themeName : "sdf"}
                  label="Theme"
                  onChange={(e) => setThemeId(e.target.value)}
                >
                  {themeSuggestion.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        )}
        {SeoDetails.page === "package" && (
          <Grid item md={12} sm={12} xs={12}>
            <Autocomplete
              id="package"
              options={packageSuggestion?.map((option) => option.title)}
              defaultValue={packageName}
              onChange={(event, newValue) => {
                handlePackageSuggestion(newValue);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Package"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
        {SeoDetails.page === "group" && (
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              select
              defaultValue={groupName}
              label="Select Other Package"
              onChange={(e) => setGroupId(e.target.value)}
            >
              {groupPackageSuggestion.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {SeoDetails.page === "things todo" && (
          <Grid item md={12} xs={12} sm={12}>
            <Grid container spacing={1}>
              {formType === "edit" && (
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    label="Previously Selected Things ToDo"
                    variant="outlined"
                    value={thingsToDoName}
                    disabled
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  fullWidth
                  select
                  defaultValue={thingsToDoName}
                  label="Things To Do"
                  onChange={(e) => setThingsToDo(e.target.value)}
                >
                  {thingsToDoData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        )}
        {SeoDetails.page === "places to visit" && (
          <Grid item md={12} xs={12} sm={12}>
            <Grid container spacing={1}>
              {formType === "edit" && (
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    label="Previously Selected Places To Visit"
                    variant="outlined"
                    value={placesToVisitName}
                    disabled
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  fullWidth
                  select
                  defaultValue={placesToVisitName}
                  label="Places To Visit"
                  onChange={(e) => setPlacesToVisit(e.target.value)}
                >
                  {placesToVisitData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        )}
        {SeoDetails.page === "story" && (
          <Grid item md={12} xs={12} sm={12}>
            <Grid container spacing={1}>
              {formType === "edit" && (
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    label="Previously Selected Story"
                    variant="outlined"
                    value={storyName}
                    disabled
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  fullWidth
                  select
                  defaultValue={storyName}
                  label="Story"
                  onChange={(e) => setStoryId(e.target.value)}
                >
                  {storyData.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Meta Description"
            variant="outlined"
            value={SeoDetails.description}
            onChange={(e) =>
              handleSeoDetailsChange("description", e.target.value)
            }
            fullWidth
            multiline
            rows={2}
            maxRows={4}
          />
        </Grid>
        <Grid md={12} sm={12} xs={12}>
          <MultiInput
            onChange={(e) => setKeywords(e.target.value)}
            setItinerary={setKeywords}
            itinerary={keywords}
          />
        </Grid>
        {/* <Grid md={12} sm={12} xs={12}>
          <h4>Favicon</h4>
          {SeoDetails.favicon ? (
            <>
              <CancelIcon
                sx={{
                  position: "relative",
                  top: "1.8rem",
                  left: "15.9rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={removeFile}
              />
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia component="img" image={SeoDetails.favicon} />
              </Card>
              <br />
              <TextField
                helperText="select images"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={handleUploadFile}
              />
            </>
          ) : (
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          )}
        </Grid> */}
        <Grid item sm={12} md={12} xs={12}>
          <Typography className="heading">OG details</Typography>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Title"
            variant="outlined"
            value={ogDetails.title}
            onChange={(e) => handleOgDetailsChange("title", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Locale"
            variant="outlined"
            value={ogDetails.locale}
            onChange={(e) => handleOgDetailsChange("locale", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Type"
            variant="outlined"
            value={ogDetails.type}
            onChange={(e) => handleOgDetailsChange("type", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Site Name"
            variant="outlined"
            value={ogDetails.siteName}
            onChange={(e) => handleOgDetailsChange("siteName", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={2}
            maxRows={4}
            value={ogDetails.description}
            onChange={(e) =>
              handleOgDetailsChange("description", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="URL"
            variant="outlined"
            value={ogDetails.url}
            onChange={(e) => handleOgDetailsChange("url", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <h4>image</h4>
          {ogDetails.image ? (
            <>
              <CancelIcon
                sx={{
                  position: "relative",
                  top: "1.8rem",
                  left: "15.9rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={removeFile}
              />
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia component="img" image={ogDetails.image} />
              </Card>
              <br />
              <TextField
                helperText="select images"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={handleUploadOgImage}
              />
            </>
          ) : (
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadOgImage}
            />
          )}
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <Typography className="heading">Twitter details</Typography>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Title"
            variant="outlined"
            value={twitterDetails.title}
            onChange={(e) =>
              handleTwitterDetailsChange("title", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Label1"
            variant="outlined"
            value={twitterDetails.label1}
            onChange={(e) =>
              handleTwitterDetailsChange("label1", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Data1"
            variant="outlined"
            value={twitterDetails.data1}
            onChange={(e) =>
              handleTwitterDetailsChange("data1", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Site Name"
            variant="outlined"
            value={twitterDetails.siteName}
            onChange={(e) =>
              handleTwitterDetailsChange("siteName", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={2}
            maxRows={4}
            value={twitterDetails.description}
            onChange={(e) =>
              handleTwitterDetailsChange("description", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Card"
            variant="outlined"
            value={twitterDetails.card}
            onChange={(e) => handleTwitterDetailsChange("card", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Creator"
            variant="outlined"
            value={twitterDetails.creator}
            onChange={(e) =>
              handleTwitterDetailsChange("creator", e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <h4>image</h4>
          {twitterDetails.image ? (
            <>
              <CancelIcon
                sx={{
                  position: "relative",
                  top: "1.8rem",
                  left: "15.9rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={removeFile}
              />
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia component="img" image={twitterDetails.image} />
              </Card>
              <br />
              <TextField
                helperText="select images"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={handleUploadTwitterImage}
              />
            </>
          ) : (
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadTwitterImage}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SeoDetails;
