import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchData,
  pageChanged,
  pageLeft,
  pageLimit,
  searchTitleChanged,
} from "./destination.slice";
import { getColumns } from "./columns-def";
import "./style.css";
import { adminRoleGetterService } from "../../shared/services";

const DestinationView = () => {
  const { data, loading, total, currentPage, searchByTitle, currentPageLimit } =
    useSelector((state) => state.destination);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");
  const user_role = adminRoleGetterService();

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    if (user_role !== "Super") {
      dispatch(searchTitleChanged(user_role));
    }
    dispatch(fetchData());
  }, [dispatch, currentPage, viewType, searchByTitle, currentPageLimit]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Destinations</Typography>
      </Grid>

      <Grid
        // item
        // xs={6}
        // md={3}
        // className="userview-navbar"
        sx={{
          display: "flex",
          margin: "1rem 0",
        }}
      >
        {user_role === "Super" && (
          <>
            <TextField
              label={`Search by Name`}
              value={searchByTitle}
              onChange={(e) => dispatch(searchTitleChanged(e.target.value))}
              fullWidth
              size="small"
              sx={{ width: "80%", marginRight: "7rem" }}
            />
            <Button
              sx={{
                width: "7rem",
              }}
              onClick={() =>
                navigate("/destination-form?formType=add", {
                  state: {
                    prevPath: `/destination`,
                  },
                })
              }
              variant="contained"
            >
              Add New
            </Button>
          </>
        )}
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default DestinationView;
