import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";
import { Link } from "react-router-dom";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "mainTitle",
      headerName: "Title",
      valueGetter: (params) =>
        params.row.mainTitle ? params.row.mainTitle : "N.A",
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1.2,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/offer-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/offer`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },

    {
      field: "supTitle",
      headerName: "Super Title",
      valueGetter: (params) =>
        params.row.supTitle ? params.row.supTitle : "N.A",
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1.2,
    },
    {
      field: "subTitle",
      headerName: "Sub Title",
      valueGetter: (params) =>
        params.row.subTitle ? params.row.subTitle : "N.A",
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
    {
      field: "imageUrl",
      headerName: "Image",
      // renderCell: (params) =>
      //   params.row.imageUrl ? params.row.imageUrl : "N.A",
      renderCell: (params) =>
        params.row.imageUrl === "" ? (
          "No Image Available"
        ) : (
          <a href={`${params.row.imageUrl}`}>Download Image</a>
        ),
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   valueGetter: (param) =>
    //     param.row.description !== "<p><br></p>"
    //       ? param.row.description.replace(/<[^>]+>/g, "")
    //       : "N.A",
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
   
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions banner={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
