import React, { Fragment, useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";

import PackageDetails from "./PackageDetails";
import Editor from "../../shared/Editor";
import TourService from "./service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DynamicForm from "./DynamicForm";
import { useForm } from "react-hook-form";
import { uploadFile, uploadMultiFile } from "../../shared/services";
import {
  adminIdGetterService,
  adminRoleGetterService,
} from "../../shared/services";
import "./style.css";
import { getFormattedDate } from "../../shared/utils";
// import { getFormattedDate } from "../../shared/SelectableSlots/utils";

const PackageForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const user_role = adminRoleGetterService();

  const [packageId, setPackageId] = useState("");
  const [featImage, setFeatImage] = useState(data ? data.featuredImage : "");
  const [images, setImages] = useState(data ? data.images : []);

  const [destinationId, setDestinationId] = useState(
    data ? data.destination._id : ""
  );
  // const [themeId, setThemeId] = useState(data ? data.themeIds?._id : "");
  const [nestedGroupingId, setNestedGroupingId] = useState(
    data ? data.nestedGrouping?.groupingId : null
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: formType === "edit" ? getFormattedDate(data.startDate) : "",
    endDate: formType === "edit" ? getFormattedDate(data.endDate) : "",
  });

  const [error, setError] = useState("");

  const adminId = adminIdGetterService();

  useEffect(() => {
    setValue("featuredImage", featImage);
  }, [featImage]);

  useEffect(() => {
    setValue("images", images);
  }, [images]);

  useEffect(() => {
    setValue("destination", destinationId);
  }, [data]);

  useEffect(() => {
    setNestedGroupingId(nestedGroupingId);
  }, [nestedGroupingId]);

  useEffect(() => {
    setPackageId(data ? data._id : "");
  }, [data]);

  const { control, handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      itinerary: formType === "edit" ? data.itinerary : [],
      title: formType === "edit" ? data.title : "",
      days: formType === "edit" ? data.days : 1,
      nights: formType === "edit" ? data.nights : "",
      basePrice: formType === "edit" ? data.basePrice : "",
      groupTour: formType === "edit" ? data.groupTour : false,
      groupSize: formType === "edit" ? data.groupSize : "",
      featuredImage: formType === "edit" ? data.featuredImage : featImage,
      images: formType === "edit" ? data.images : images,
      ratings: formType === "edit" ? data.ratings : "",
      cab: formType === "edit" ? data.cab : "",
      startlocation: formType === "edit" ? data.startlocation : "",
      endlocation: formType === "edit" ? data.endlocation : "",
      inclusions: formType === "edit" ? data.inclusions : "",
      exclusions: formType === "edit" ? data.exclusions : "",
      description: formType === "edit" ? data.description : "",
      destinationName: formType === "edit" ? data.destination?.name : "",
      themeIds:
        formType === "edit" ? data.themeIds?.map((item) => item._id) : [],
      themeName:
        formType === "edit" ? data.themeIds?.map((item) => item.name) : "",
      todayTopDeal: formType === "edit" ? data.todayTopDeal : "",
      nestedGroupingName:
        formType === "edit" ? data.nestedGrouping?.groupingId?.name : null,
      popular: formType === "edit" ? data.popular : false,
    },
  });

  const navigate = useNavigate();

  const handleFormSubmit = async (data) => {
    const payload = {
      title: data.title,
      days: +data.days,
      nights: +data.nights,
      basePrice: +data.basePrice,
      groupTour: data.groupTour,
      groupSize: +data.groupSize,
      featuredImage: data.featuredImage ? data.featuredImage : "",
      images: data.images,
      ratings: data.ratings,
      cab: data.cab,
      startlocation: data.startlocation,
      endlocation: data.endlocation,
      inclusions: data.inclusions,
      exclusions: data.exclusions,
      description: data.description,
      destination: data.destination,
      themeIds: data.themeIds,
      itinerary: data.fieldArray,
      todayTopDeal: data.todayTopDeal,
      nestedGrouping: {
        groupingId: nestedGroupingId,
        isTrue: nestedGroupingId !== null ? true : false,
      },
      popular: data.popular,
      createdBy: adminId,
      ...selectedDateRange,
    };

    const editPayload = {
      title: data.title,
      days: +data.days,
      nights: +data.nights,
      basePrice: +data.basePrice,
      groupTour: data.groupTour,
      groupSize: +data.groupSize,
      featuredImage: data.featuredImage ? data.featuredImage : "",
      images: data.images,
      ratings: data.ratings,
      cab: data.cab,
      startlocation: data.startlocation,
      endlocation: data.endlocation,
      inclusions: data.inclusions,
      exclusions: data.exclusions,
      description: data.description,
      destination: data.destination,
      themeIds: data.themeIds,
      itinerary: data.fieldArray,
      todayTopDeal: data.todayTopDeal,
      nestedGrouping: {
        groupingId: nestedGroupingId,
        isTrue: nestedGroupingId !== null ? true : false,
      },
      popular: data.popular,
      ...selectedDateRange,
    };

    const { error, isSuccess } =
      formType === "add"
        ? await TourService.addPackage(payload)
        : await TourService.editPackage(editPayload, packageId);
    isSuccess && navigate("/tour?view-type=tour-packages");
    error && handleError(error);
  };

  const handleDateSelection = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];
    let formData = new FormData();
    formData.append("file", files);
    let payload = formData;

    try {
      const { fileUrl, isSuccess, error } = await uploadFile(payload);
      isSuccess && setFeatImage(fileUrl);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };
  const handleMultipleUploadFile = async ({ target }) => {
    const files = target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    try {
      const { fileUrl, isSuccess, error } = await uploadMultiFile(formData);
      let files = [];
      fileUrl.map((item) => {
        files.push(item.fileLocation);
      });
      isSuccess && imagesHandler(files);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const imagesHandler = (files) => {
    if (images.length === 0) {
      setImages(files);
    } else {
      setImages([...images, ...files]);
    }
  };

  const resetImage = (index) => {
    const newImages = images.filter((item, ind) => ind !== index);
    setImages(newImages);
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="package-form-container">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <PackageDetails
          setValue={setValue}
          register={register}
          packageDetails={getValues()}
          handleUploadFile={handleUploadFile}
          handleMultipleUploadFile={handleMultipleUploadFile}
          formType={formType}
          user_role={user_role}
          selectedDateRange={selectedDateRange}
          handleDateSelection={handleDateSelection}
          featImage={featImage}
          setFeatImage={setFeatImage}
          images={images}
          resetImage={resetImage}
          setNestedGroupingId={setNestedGroupingId}
          nestedGroupingId={nestedGroupingId}
          control={control}
        />
        <br />
        <hr />
        <br />

        <Typography style={{ fontWeight: "bold" }}>Description -</Typography>
        <Editor
          content={getValues().description}
          setContent={(data) => setValue("description", data)}
          placeholder={"Description"}
        />

        <Typography style={{ fontWeight: "bold" }}>Inclusions -</Typography>
        <Editor
          content={getValues().inclusions}
          setContent={(data) => setValue("inclusions", data)}
          placeholder={"Inclusions"}
        />

        <Typography style={{ fontWeight: "bold" }}>Exclusions -</Typography>
        <Editor
          content={getValues().exclusions}
          setContent={(data) => setValue("exclusions", data)}
          placeholder={"Exclusions"}
        />

        <DynamicForm
          setValue={setValue}
          register={register}
          control={control}
          items={getValues().itinerary}
          formType={formType}
          maxItinerary={getValues().packageDetails?.days}
        />

        <Button
          type="submit"
          className="package-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default PackageForm;
