import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import * as yup from "yup";
import Editor from "../../shared/Editor";

import { defaultHeroSectionViewDetails } from "./constants";

import FaqService from "../faq/service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import "./style.css";
// import FaqDetails from "./FaqDetails";
import { HeroSectionViewFormService } from "./service";
import { DeleteForever } from "@mui/icons-material";
import { storeHeroSection } from "./heroSection.slice";

const getSchema = (formType) => {
  const commonSchema = {
    // mainTitle: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const HeroSectionDetailsFormView = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const formType = params.get("formType");

  const dispatch = useDispatch();

  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const response =
        await HeroSectionViewFormService.getHeroSectionDetailsView();
      dispatch(storeHeroSection(response?.data?.getData[0]));
      setData(response?.data?.getData[0]?.landingData);
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container className="trainer-form-container">
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "2rem" }}>
            Hero Section Details
          </Typography>
          <Button
            onClick={() =>
              navigate("/hero-section-form?formType=edit", {
                state: {
                  prevPath: `/hero-section`,
                },
              })
            }
            variant="contained"
          >
            Edit form
          </Button>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="column"
          sx={{ marginTop: "1rem" }}
        >
          <Grid item>
            <h4>Title</h4>
            <TextField value={data?.title} disabled />
          </Grid>
          <Grid item>
            <h4>Subtitle</h4>
            <TextField value={data?.subTitle} disabled />
          </Grid>

          <Grid item>
            <h4>Image</h4>
            {data?.imgUrl ? (
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia component="img" image={data?.imgUrl} />
              </Card>
            ) : (
              "No Image Uploaded"
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HeroSectionDetailsFormView;
