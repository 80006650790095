import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultEnquiryDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import GroupTourEnquiryDetails from "./GroupTourEnquiryDetails";
import TripEnquiryFormService from "./service";
import "./style.css";
import { getFormattedDate } from "../../shared/utils";

const getSchema = (formType) => {
  const commonSchema = {
    // title: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const GroupTourEnquiryForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [enquiryDetails, setEnquiryDetails] = useState(
    formType === "edit" ? { ...data } : defaultEnquiryDetails
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...enquiryDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        ...data,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await TripEnquiryFormService.addEnquiry(payload)
          : await TripEnquiryFormService.updateEnquiry(payload, payload._id);
      isSuccess && navigate("/group-tour-enquiry");
      setError(error);
      error && handleError(error);
    } catch (error) {
      // handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setEnquiryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <GroupTourEnquiryDetails
          TripEnquiryDetails={enquiryDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default GroupTourEnquiryForm;
