import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GroupTourEnquiryService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchByLocation: "",
  status: "",
};

export const fetchGroupTourEnquiryData = createAsyncThunk(
  "groupTourEnquiry/fetchGroupTourEnquiry",
  async (_, thunkAPI) => {
    const { searchKey, currentPage, currentPageLimit, status } =
      thunkAPI.getState().groupTourEnquiry;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      status,
    };
    const { data, count } = await GroupTourEnquiryService.fetchGroupTourEnquiry(
      payload
    );
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return { data, count };
  }
);

export const deleteUser = createAsyncThunk(
  "groupTourEnquiry/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await GroupTourEnquiryService.deleteUser(id);
    if (isSuccess) {
      return id;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const groupTourEnquirySlice = createSlice({
  name: "groupTourEnquiry",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByLocation = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
    statusChanged(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupTourEnquiryData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchGroupTourEnquiryData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.count;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchLocationChanged,
  pageLeft,
  pageLimit,
  statusChanged,
} = groupTourEnquirySlice.actions;
export default groupTourEnquirySlice.reducer;
