import { axiosInstance } from "../../shared/axios";

const fetchCallEnquiry = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  status,
}) => {
  try {
    const response = await axiosInstance.get(
      `/callEnquiry?limit=${currentPageLimit}&page=${currentPage}&search=${searchKey}&status=${
        status === "all" ? "" : status
      }`,
      null
    );

    return response;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const fetchDestination = async () => {
  try {
    const { data } = await axiosInstance.get(`/admins/destination`, null);
    return { data: data.data };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const CallEnquiryService = {
  fetchCallEnquiry,
  fetchDestination,
};
