import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DestinationService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchByTitle: "",
};

export const fetchData = createAsyncThunk(
  "destination/fetchData",
  async (_, thunkAPI) => {
    const { searchByTitle, currentPage, currentPageLimit } =
      thunkAPI.getState().destination;
    const payload = {
      currentPage,
      currentPageLimit,
      searchByTitle,
    };
    const { data, total, error } = await DestinationService.fetchDestination(
      payload
    );

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data, total };
  }
);

export const deleteDestination = createAsyncThunk(
  "destination/deleteDestination",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await DestinationService.deleteDestination(id);
    if (isSuccess) {
      thunkAPI.dispatch(fetchData());
      return isSuccess;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const destinationSlice = createSlice({
  name: "destination",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    searchTitleChanged(state, action) {
      state.searchByTitle = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.loading = "succeeded";
    });
  },
});

export const { pageChanged, searchTitleChanged, pageLeft, pageLimit } =
  destinationSlice.actions;
export default destinationSlice.reducer;
