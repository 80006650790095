import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { TourReducer } from "../features/tours";
import { DestinationReducer } from "../features/destination";
import { StatsReducer } from "../features/statistics";
import { AchievementReducer } from "../features/trainer-achievements";
import { MonitoringReducer } from "../features/monitoring";
import { PayoutReducer } from "../features/payout";
import { PlansReducer } from "../features/payment-plans";
import { CancelPlanReducer } from "../features/subscription";
import { EnquiryReducer } from "../features/enquiries";
import { EmployeeReducer } from "../features/employees";
import { AuthReducer } from "../features/auth";
import { FacilityReducer } from "../features/facility";
import { BannerReducer } from "../features/banner";
import { BookingReducer } from "../features/booking";
import { FaqReducer } from "../features/faq";
import { SeoReducer } from "../features/seo";
import { AboutUsReducer } from "../features/aboutUs";
import { HeroSectionReducer } from "../features/hero-section";
import { ReviewReducer } from "../features/traveller-reviews";
import { StoryReducer } from "../features/traveller-stories";
import { TripEnquiryReducer } from "../features/plan-your-trip-enquiries";
import { TourEnquiryReducer } from "../features/normal-tour-enquiry";
import { GroupTourEnquiryReducer } from "../features/group-tour-enquiry";
import { UserReducer } from "../features/users";
import { GroupReducer } from "../features/nestedGrouping";
import { GroupToursReducer } from "../features/group-tours";
import { TodaysTopDealReducer } from "../features/todays-top-deal";
import { CallEnquiryReducer } from "../features/call-enquiry";
import { SocialReducer } from "../features/social-link";
import { codeSnippetReducer } from "../features/code-snippet";

const reducer = combineReducers({
  tour: TourReducer,
  destination: DestinationReducer,
  stat: StatsReducer,
  achievement: AchievementReducer,
  monitoring: MonitoringReducer,
  payout: PayoutReducer,
  plans: PlansReducer,
  cancelPlan: CancelPlanReducer,
  enquiry: EnquiryReducer,
  tripEnquiry: TripEnquiryReducer,
  tourEnquiry: TourEnquiryReducer,
  callEnquiry: CallEnquiryReducer,
  groupTourEnquiry: GroupTourEnquiryReducer,
  employees: EmployeeReducer,
  auth: AuthReducer,
  facility: FacilityReducer,
  banner: BannerReducer,
  booking: BookingReducer,
  faq: FaqReducer,
  seo: SeoReducer,
  socialLink: SocialReducer,
  codeSnippet: codeSnippetReducer,
  heroSection: HeroSectionReducer,
  aboutUS: AboutUsReducer,
  review: ReviewReducer,
  story: StoryReducer,
  user: UserReducer,
  group: GroupReducer,
  groupTours: GroupToursReducer,
  todaysTopDeal: TodaysTopDealReducer,
});
const store = configureStore({
  reducer,
  devTools: true,
});
export default store;
