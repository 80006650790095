import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { enquiries } from "./constants";

const EnquiryDetails = ({
  EnquiryDetails: { comment, status, query, firstName, phoneNo },
  handleDetailsChange,
  formType,
}) => {
  return (
    <>
      <Typography className="heading">Update Enquiry Status</Typography>

      <label>
        <b>{firstName}'s</b> contact number: {phoneNo}
      </label>

      <label>
        <b>{firstName}'s</b> Query:
      </label>
      <TextField
        variant="outlined"
        value={query}
        fullWidth
        multiline
        disabled
        rows={2}
        sx={{ backgroundColor: "#f9f4f4" }}
      />

      <TextField
        label="Comment"
        variant="outlined"
        value={comment}
        onChange={(e) => handleDetailsChange("comment", e.target.value)}
        fullWidth
      />

      <Autocomplete
        id="status"
        options={enquiries}
        value={status}
        defaultValue={status}
        // onChange={(event, newValue) => {
        //   handleDestinationSuggestion(newValue);
        // }}
        onChange={(event, newValue) => handleDetailsChange("status", newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Status" variant="outlined" fullWidth />
        )}
      />

      {/* <FormControl fullWidth>
  <InputLabel>Status of Enquiry</InputLabel>
  <Select
    value={status}
    label="Status of Enquiry"
    onChange={(e) => handleDetailsChange("status", e.target.value)}
  >
    <MenuItem>Answered</MenuItem>
    <MenuItem>Requested</MenuItem>
  </Select>
</FormControl> */}
    </>
  );
};

export default EnquiryDetails;
