import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import {
  defaultSeoDetails,
  defaultOgDetails,
  defaultTwitterDetails,
} from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { uploadFile } from "../../shared/services";
import SeoDetails from "./SeoDetails";
import SeoFormService from "./service";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {};
  return yup.object().shape(commonSchema);
};

const SeoForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();
  const { control } = useForm();

  const [seoId, setSeoId] = useState(formType === "edit" ? data._id : "");

  const [seoDetails, setSeoDetails] = useState(
    formType === "edit"
      ? {
          description: data.description,
          page: data.page,
          title: data.title,
          keywords: data.keywords,
          favicon: data.favicon,
        }
      : defaultSeoDetails
  );
  const [ogDetails, setOgDetails] = useState(
    formType === "edit" ? data.og : defaultOgDetails
  );
  const [twitterDetails, setTwitterDetails] = useState(
    formType === "edit" ? data.twitter : defaultTwitterDetails
  );

  const [packageId, setPackageId] = useState(
    formType === "edit" ? data.packageId?._id : ""
  );
  const [packageName, setPackageName] = useState(
    formType === "edit" ? data.packageId?.title : ""
  );
  const [groupId, setGroupId] = useState(
    formType === "edit" ? data.groupId?._id : ""
  );
  const [groupName, setGroupName] = useState(
    formType === "edit" ? data.groupId?.name : ""
  );

  const [destinationId, setDestinationId] = useState(
    formType === "edit" ? data.destinationId?._id : ""
  );
  const [destinationName, setDestinationName] = useState(
    formType === "edit" ? data.destinationId?.name : ""
  );
  const [themeId, setThemeId] = useState(
    formType === "edit" ? data.themeId?._id : ""
  );
  const [themeName, setThemeName] = useState(
    formType === "edit" ? data.themeId?.name : ""
  );
  const [thingsToDo, setThingsToDo] = useState(
    formType === "edit" ? data.storyId?._id : ""
  );
  const [thingsToDoName, setThingsToDoName] = useState(
    formType === "edit" ? data.storyId?.title : ""
  );
  const [placesToVisit, setPlacesToVisit] = useState(
    formType === "edit" ? data.storyId?.id : ""
  );
  const [placesToVisitName, setPlacesToVisitName] = useState(
    formType === "edit" ? data.storyId?.title : ""
  );
  const [storyName, setStoryName] = useState(
    formType === "edit" ? data.storyId?.title : ""
  );
  const [storyId, setStoryId] = useState(
    formType === "edit" ? data.storyId?.id : ""
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate({
        seoDetails,
        ogDetails,
        twitterDetails,
        packageId,
        groupId,
        destinationId,
        themeId,
        placesToVisit,
        thingsToDo,
        storyId,
      });

      const payload = {
        title: data.seoDetails.title,
        page: data.seoDetails.page,
        description: data.seoDetails.description,
        keywords: data.seoDetails.metaKeywords,
        slug: data.seoDetails.slug,
        og: data.ogDetails,
        twitter: data.twitterDetails,
        ...(destinationId && { destinationId: data.destinationId }),
        ...(packageId && { packageId: data.packageId }),
        ...(groupId && { groupId: data.groupId }),
        ...(themeId && { themeId: data.themeId }),
        ...(storyId && { storyId: data.storyId }),
        ...(placesToVisit && { placesToVisit: data.placesToVisit }),
        ...(thingsToDo && { thingsToDo: data.thingsToDo }),
      };

      const editPayload = {
        title: data.seoDetails.title,
        page: data.seoDetails.page,
        description: data.seoDetails.description,
        keywords: data.seoDetails.metaKeywords,
        og: ogDetails,
        twitter: twitterDetails,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await SeoFormService.addSeo(payload)
          : await SeoFormService.editSeo(editPayload, seoId);
      isSuccess && navigate("/seo");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSeoDetailsChange = (name, value) => {
    setSeoDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleOgDetailsChange = (name, value) => {
    setOgDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleTwitterDetailsChange = (name, value) => {
    setTwitterDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setSeoDetails((data) => ({ ...data, ["favicon"]: res?.fileUrl }));
    } catch (error) {}
  };
  const handleUploadOgImage = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setOgDetails((data) => ({ ...data, ["image"]: res?.fileUrl }));
    } catch (error) {}
  };
  const handleUploadTwitterImage = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setTwitterDetails((data) => ({ ...data, ["image"]: res?.fileUrl }));
    } catch (error) {}
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <SeoDetails
          SeoDetails={seoDetails}
          ogDetails={ogDetails}
          twitterDetails={twitterDetails}
          packageId={packageId}
          setPackageId={setPackageId}
          destinationName={destinationName}
          themeName={themeName}
          packageName={packageName}
          groupId={groupId}
          setGroupId={setGroupId}
          groupName={groupName}
          storyName={storyName}
          setStoryName={setStoryName}
          storyId={storyId}
          setStoryId={setStoryId}
          placesToVisitName={placesToVisitName}
          thingsToDoName={thingsToDoName}
          destinationId={destinationId}
          setDestinationId={setDestinationId}
          themeId={themeId}
          setThemeId={setThemeId}
          thingsToDo={thingsToDo}
          setThingsToDo={setThingsToDo}
          placesToVisit={placesToVisit}
          setPlacesToVisit={setPlacesToVisit}
          handleSeoDetailsChange={handleSeoDetailsChange}
          handleOgDetailsChange={handleOgDetailsChange}
          handleTwitterDetailsChange={handleTwitterDetailsChange}
          handleUploadFile={handleUploadFile}
          handleUploadOgImage={handleUploadOgImage}
          handleUploadTwitterImage={handleUploadTwitterImage}
          formType={formType}
          control={control}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default SeoForm;
