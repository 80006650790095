import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { enquiries } from "./constants";
import CallEnquiryFormService from "../call-enquiry-form/service";

const TripEnquiryDetails = ({
  TripEnquiryDetails: {
    comment,
    status,
    phone,
    email,
    name,
    hotelsCategory,
    additionalRequirementText,
    additionalRequirementFile,
    travelDate,
    returnDate,
    from,
    to,
    pickUp,
    dropOff,
    noOfPersons,
    queryID,
    // cab,
  },
  handleDetailsChange,
  formType,
}) => {
  return (
    <>
      <Typography className="heading">Update Enquiry Status</Typography>
      <label>
        <b>{name}'s</b> contact details: {phone}
      </label>

      <label>
        <b>1. Phone</b> {phone}
      </label>

      <label>
        <b>2. Email</b> {email}
      </label>

      <label>
        <b>Hotel Category</b>
      </label>

      <Rating disabled defaultValue={hotelsCategory} />

      {/* <label>
        <b>Cab Category</b>
      </label>
      <TextField
        variant="outlined"
        value={cab}
        fullWidth
        disabled
        sx={{ backgroundColor: "#f9f4f4" }}
      /> */}

      <label>
        <b>Travel Date</b>
      </label>

      <TextField
        variant="outlined"
        value={travelDate}
        fullWidth
        disabled
        sx={{ backgroundColor: "#f9f4f4" }}
      />

      <label>
        <b>Return Date</b>
      </label>

      <TextField
        variant="outlined"
        value={returnDate}
        fullWidth
        disabled
        sx={{ backgroundColor: "#f9f4f4" }}
      />

      <label>
        <b>From - To</b>
      </label>
      <TextField
        variant="outlined"
        value={`${from} - ${to}`}
        fullWidth
        disabled
        sx={{ backgroundColor: "#f9f4f4" }}
      />

      <label>
        <b>Pick Up - Drop</b>
      </label>
      <TextField
        variant="outlined"
        value={`${pickUp} - ${dropOff}`}
        fullWidth
        disabled
        sx={{ backgroundColor: "#f9f4f4" }}
      />

      <label>
        <b>No. of person</b>
      </label>
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "1rem" }}>Adults- {noOfPersons.adults}</p>
        <p style={{ marginRight: "1rem" }}>Child- {noOfPersons.child}</p>
        <p>Kids- {noOfPersons.kids}</p>
      </div>
      <label style={{ marginTop: "1rem" }}>
        <b>Query ID</b>
      </label>
      <div>{queryID}</div>
      <label>
        <b>{name}'s</b> Query:
      </label>
      <TextField
        variant="outlined"
        value={additionalRequirementText}
        fullWidth
        multiline
        disabled
        rows={3}
        sx={{ backgroundColor: "#f9f4f4" }}
      />
      <Grid item>
        <label>
          <b>Additional Requirements Photo</b>
        </label>
        {additionalRequirementFile ? (
          <Card
            raised
            sx={{
              maxWidth: 280,
              maxHeight: 280,
              margin: "0",
              padding: "0.1em",
            }}
          >
            <CardMedia component="img" image={additionalRequirementFile} />
          </Card>
        ) : (
          <>
            <br />
            No Image Uploaded
          </>
        )}
      </Grid>
      <TextField
        label="Comment"
        variant="outlined"
        value={comment}
        onChange={(e) => handleDetailsChange("comment", e.target.value)}
        fullWidth
      />
      <Autocomplete
        id="status"
        options={enquiries}
        value={status}
        defaultValue={status}
        // onChange={(event, newValue) => {
        //   handleDestinationSuggestion(newValue);
        // }}
        onChange={(event, newValue) => handleDetailsChange("status", newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Status" variant="outlined" fullWidth />
        )}
      />
    </>
  );
};

export default TripEnquiryDetails;
