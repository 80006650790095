import React from "react";

import { Route, Routes } from "react-router-dom";
import { adminRoleGetterService } from "./shared/services";

import Login from "./features/auth";

import { PackageForm, ThemeForm } from "./features/package-form/index.js";

import PrivateRoute from "./features/routes/PrivateRoute";
import PublicRoute from "./features/routes/PublicRoute";
import SidebarLayout from "./features/side-bar/SidebarLayout";
import Statistics from "./features/statistics";
import TourView from "./features/tours/TourView";

import "./App.css";
import Register from "./features/auth/Register";
import { DestinationForm, DestinationView } from "./features/destination";
import EmployeeView from "./features/employees/EmployeeView";
import { FacilityForm } from "./features/facility-form";
import FacilityView from "./features/facility/FacilityView";

import AboutUs from "./features/aboutUs/AboutUs";
import BannerView from "./features/banner";
import { BannerForm } from "./features/banner-form";

import EnquiryView from "./features/enquiries";
import FaqView from "./features/faq";
import { FaqForm } from "./features/faq-form";
import { HeroSectionDetailsFormView } from "./features/hero-section";
import { HeroSectionFormEdit } from "./features/hero-section-form";
import SeoView from "./features/seo";
import { SeoForm } from "./features/seo-form";
import ReviewView from "./features/traveller-reviews";
import { ReviewForm } from "./features/traveller-reviews-form";
import { EnquiryForm } from "./features/enquiries-form";
import StoryView from "./features/traveller-stories";
import PlaceView from "./features/famous-places";
import TripEnquiryView from "./features/plan-your-trip-enquiries/TripEnquiryView";
import { TripEnquiryForm } from "./features/plan-your-trip-enquiries-form";
import TourEnquiryView from "./features/normal-tour-enquiry";
import GroupTourEnquiryView from "./features/group-tour-enquiry";
import { StoryForm } from "./features/traveller-stories-form";
import { PlaceForm } from "./features/famous-places-form";
import AboutUsForm from "./features/aboutUs/aboutUs-form/AboutUsForm";
import { GroupTourEnquiryForm } from "./features/group-tour-enquiry-form";
import UserView from "./features/users/UserView";
import ThingView from "./features/things-to-do";
import { ThingForm } from "./features/things-todo-form";
import GroupView from "./features/nestedGrouping/GroupView";
import GroupForm from "./features/nestedGrouping/GroupForm";
import GroupTours from "./features/group-tours";
import { GroupToursForm } from "./features/group-tours-form";
import TodaysTopDeal from "./features/todays-top-deal";
import { TodaysTopDealForm } from "./features/todays-top-deal-form";
import CallEnquiryView from "./features/call-enquiry";
import { CallEnquiryForm } from "./features/call-enquiry-form";
import { SocialLinkForm } from "./features/social-link-form";
import SocialLinksView from "./features/social-link/SocialLinksView";
import CodeSnippet from "./features/code-snippet";
import { CodeSnippetForm } from "./features/code-snippet-form";
import { NormalTourEnquiryForm } from "./features/normal-tour-enquiry-form";
function App() {
  const admin_role = adminRoleGetterService();
  const permitAdmin = admin_role === "Super" ? true : false;
  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route
          path="/tour"
          element={
            <PrivateRoute permitAdmin={true}>
              <TourView />
            </PrivateRoute>
          }
        />
        <Route
          path="/destination"
          element={
            <PrivateRoute permitAdmin={true}>
              <DestinationView />
            </PrivateRoute>
          }
        />

        <Route
          path="/employee-details"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <EmployeeView />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/statistics"
          element={
            <PrivateRoute permitAdmin={true}>
              <Statistics />
            </PrivateRoute>
          }
        />

        <Route
          path="/package-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <PackageForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/destination-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <DestinationForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/theme-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <ThemeForm />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/payout"
          element={
            <PrivateRoute>
              <Payout />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-plans"
          element={
            <PrivateRoute>
              <PaymentPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-plan-form"
          element={
            <PrivateRoute>
              <PaymentPlanForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/cancel-subscription"
          element={
            <PrivateRoute>
              <CancelSubscription />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription-detail"
          element={
            <PrivateRoute>
              <ShowPlanDetail />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/user-enquiry"
          element={
            <PrivateRoute permitAdmin={true}>
              <EnquiryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/enquiry-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <EnquiryForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/trip-enquiry"
          element={
            <PrivateRoute permitAdmin={true}>
              <TripEnquiryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/trip-enquiry-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <TripEnquiryForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/tour-enquiry"
          element={
            <PrivateRoute permitAdmin={true}>
              <TourEnquiryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/tour-enquiry-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <NormalTourEnquiryForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/group-tour-enquiry"
          element={
            <PrivateRoute permitAdmin={true}>
              <GroupTourEnquiryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/group-tour-enquiry-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <GroupTourEnquiryForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/call-enquiry"
          element={
            <PrivateRoute permitAdmin={true}>
              <CallEnquiryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/call-enquiry-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <CallEnquiryForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/famous-places"
          element={
            <PrivateRoute permitAdmin={true}>
              <PlaceView />
            </PrivateRoute>
          }
        />

        <Route
          path="/famous-places-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <PlaceForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/things"
          element={
            <PrivateRoute permitAdmin={true}>
              <ThingView />
            </PrivateRoute>
          }
        />

        <Route
          path="/things-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <ThingForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/facility"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <FacilityView />
            </PrivateRoute>
          }
        />
        <Route
          path="/facility-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <FacilityForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/offer"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <BannerView />
            </PrivateRoute>
          }
        />

        <Route
          path="/offer-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <BannerForm />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/bookings"
          element={
            <PrivateRoute>
              <BookingView />
            </PrivateRoute>
          }
        />

        <Route
          path="/booking-form"
          element={
            <PrivateRoute>
              <BookingForm />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/faq"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <FaqView />
            </PrivateRoute>
          }
        />

        <Route
          path="/faq-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <FaqForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/about-us"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <AboutUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/about-us-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <AboutUsForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/nestedGroup"
          element={
            <PrivateRoute permitAdmin={true}>
              <GroupView />
            </PrivateRoute>
          }
        />
        <Route
          path="/nestedGroup-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <GroupForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/seo"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <SeoView />
            </PrivateRoute>
          }
        />
        <Route
          path="/seo-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <SeoForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/socail-link"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <SocialLinksView />
            </PrivateRoute>
          }
        />
        <Route
          path="/social-link-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <SocialLinkForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/codeSnippet-view"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <CodeSnippet />
            </PrivateRoute>
          }
        />
        <Route
          path="/code-snippet-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <CodeSnippetForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/groupTourView"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <GroupTours />
            </PrivateRoute>
          }
        />
        <Route
          path="/group-tour-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <GroupToursForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/todaysTopDeal"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <TodaysTopDeal />
            </PrivateRoute>
          }
        />
        <Route
          path="/todaysTopDeal-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <TodaysTopDealForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/hero-section"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <HeroSectionDetailsFormView />
            </PrivateRoute>
          }
        />

        <Route
          path="/hero-section-form"
          element={
            <PrivateRoute permitAdmin={permitAdmin}>
              <HeroSectionFormEdit />
            </PrivateRoute>
          }
        />

        <Route
          path="/review"
          element={
            <PrivateRoute permitAdmin={true}>
              <ReviewView />
            </PrivateRoute>
          }
        />

        <Route
          path="/review-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <ReviewForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/story"
          element={
            <PrivateRoute permitAdmin={true}>
              <StoryView />
            </PrivateRoute>
          }
        />

        <Route
          path="/story-form"
          element={
            <PrivateRoute permitAdmin={true}>
              <StoryForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/users"
          element={
            <PrivateRoute permitAdmin={true}>
              <UserView />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path="/accept-invite"
        element={
          <PrivateRoute permitAdmin={true}>
            <Register />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
}

export default App;
