import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchTourEnquiry = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  status,
}) => {
  try {
    const response = await axiosInstance.get(
      `/packages/group/enquiry?type=normal&limit=${currentPageLimit}&search=${searchKey}&status=${
        status === "all" ? "" : status
      }`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const TourEnquiryService = {
  fetchTourEnquiry,
};
