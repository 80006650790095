import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTourEnquiryData,
  pageChanged,
  pageLeft,
  pageLimit,
  searchKeyChanged,
  sortingChanged,
  statusChanged,
} from "./tourEnquiry.slice";
import { getColumns } from "./columns-def";
import "./style.css";

const TourEnquiryView = () => {
  const {
    data,
    loading,
    total,
    currentPage,
    sortingInfo,
    searchKey,
    currentPageLimit,
    remarkStatus,
    status,
  } = useSelector((state) => state.tourEnquiry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTourEnquiryData());
  }, [
    dispatch,
    currentPage,
    viewType,
    sortingInfo,
    searchKey,
    currentPageLimit,
    remarkStatus,
    status,
  ]);

  const [open, setOpen] = useState(false);

  const statusOptions = [
    {
      lable: "Answered",
      value: "answered",
    },
    {
      lable: "Requested",
      value: "requested",
    },
    {
      lable: "Converted",
      value: "converted",
    },
    {
      lable: "All",
      value: "all",
    },
  ];

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Tour Enquiries</Typography>
      </Grid>
      {/* <Grid
        container
        className="userview-navbar"
        sx={{ display: "flex", justifyContent: "end !important" }}
      > */}
      <Grid container spacing={1} sx={{ display: "flex", margin: "1rem 0" }}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={`Search by Name, Email, and Query id`}
            value={searchKey}
            onChange={(e) => dispatch(searchKeyChanged(e.target.value))}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <TextField
            label={`Search by Status`}
            select
            value={status}
            onChange={(e) => dispatch(statusChanged(e.target.value))}
            size="small"
            fullWidth
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.lable}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <DataGrid
        rows={data ? data : []}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default TourEnquiryView;
